/* Patient Information Section */

@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&family=Open+Sans:wght@500;700&family=Young+Serif&display=swap');


.form-container {
    display: flex;
    justify-content: center;
}

.form-check-input:checked {
    background-color: black;
}

.form-control {
    border-radius: 2px;
}

.form-label {
    color: grey;
}

.top-field {
    margin-top: 10px;
}

.mid-field {
    margin-top: 10px;
}

.bottom-field {
    margin-top: 10px;
}

.container-form-top {
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.container-form-mid {
    display: flex;
    justify-content: center;
}

/* mid row */
.row {
    display: flex;
    justify-content: center;
}

.container-form-bottom {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

}

.amount-label {
    display: flex;
    justify-content: center;
}

.amount-field {
    width: 250px;
}

.radio-btn-container {
    display: flex;
    justify-content: center;
    justify-content: space-evenly;

    margin-top: 10px;
}

.btn-patient-info:disabled, .btn-contract:disabled, .btn-idPage:disabled, .btn-invoice:disabled, .btn-payAuth:disabled {
    background-color: #4bb543;
    border: #4bb543;
    border-radius: 2px;
    width: 250px;
}

.btn-contract {
    background-color: #4bb543;
    border: #4bb543;
    border-radius: 2px;
    width: 250px;
}

.btn-patient-info {
    background-color: #4bb543;
    border: #4bb543;
    border-radius: 2px;
    width: 250px;
}

.btn-patient-info:hover, .btn-contract:hover, .btn-idPage:hover, .btn-invoice:hover, .btn-payAuth:hover {
    background-color: #4bb543;
}

.info-btn-location, .btn-contract-location {
    display: flex;
    justify-content: center;
    margin: 20px;
}

/* Patient Invoice Section */

.btn-invoice, .btn-contract {
    border: #4bb543;
    background-color: #4bb543;
    border-radius: 2px;
    width: 250px;
}

.btn-location {
    display: flex;
    justify-content: center;
    margin: 12px;
}

.btn-payAuth, .btn-idPage {
    border: #4bb543;
    background-color: #4bb543;
    border-radius: 2px;
    width: 250px;
}


.form-select {
    font-weight: 700;
}

.form-selection {
    font-weight: 700;
}

.form-check {
    font-weight: 700;
}

.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
}

.conf-container {
    margin-top: 75px;
    margin-bottom: 20px;
}

.conf-checkmark {
    display: flex;
    justify-content: center;

}

.ref-div {
    margin-top: 25px;
    text-align: center;
    padding-left: 75px;
    padding-right: 75px;

}

.text-ref-div {
    margin-top: 50px;
    text-align: center;
    padding-left: 50px;
    padding-right: 50px;
}

.date-item, .date-location-item {
    display: flex;
    justify-content: flex-end;

}

.date-row-item {
    margin-bottom: 0;
    padding: 0;
}

p {
    margin-bottom: 0;
}

.id-title {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 25px;
}

.id-text-instructions {
    display: flex;
    justify-content: center;
    color: grey;
}

.iframe {
    width: 100%;
}

.error-display-container {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 100px;
    /* font-family: 'Open Sans', sans-serif; */
    /* font-family: 'Young Serif', serif; */
    font-family: 'Noto Serif', serif;
}

.error-card {
    /* color: white; */
    max-width: 600px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 5px;
    /* background-color: #9B64C0; */
}

.error-box {
    margin: 50px;
}

.dropdown-states {
    background-color: whitesmoke;
}

.feedback-msg {
    width: 235px;
}

.spinner {
    color: #4bb543;
    display: block;
    position: fixed;
    z-index: 1031; /* High z-index so it is on top of the page */
    top: 50%;
    right: 50%; /* or: left: 50%; */
    left: calc(50% - (50px / 2))
}

.contract-title {
    margin-top: 50px;
    margin-bottom: 25px;
}

















