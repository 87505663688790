
.step-item .step-count {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #4bb543;
    color: whitesmoke;
    font-weight: bold;
}

.step-item.completed {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.text-cont {
    width: 70%;
    margin-left: 25px;
    color: grey;
}

.step-wrap {
    margin: 20px;
}

.id-container {
    display: flex;
    justify-content: center;
}
